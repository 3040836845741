import { ColDef } from "ag-grid-community";
import MilestonesApi from "../../../../../../services/api/v2/milestones/Milestones.api";
import { BaseGridColumnBuilder } from "../base/BaseGridColumnBuilder";
import { CommonColDefFieldNamesEnum, MilestoneColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { NAME_COLUMN_CONFIG, NAME_FILTER_CONFIG } from "../../columns/commonColumns/NameColumn/NameColumn_config";
import { DateColumnBuilder } from "../../columns/commonColumns/DateColumn/DateColumn_builder";
import { DATE_COLUMN_CONFIG, DATE_FILTER_CONFIG } from "../../columns/commonColumns/DateColumn/DateColumn_config";
import I18n from "../../../../../localization/I18n";
import { areDatesEqual, stringOrMomentToDate, stringToDate } from "../../../../GridHelpers";
import { MilestoneField } from "../../../../../../enums";
import { DescriptionColumnBuilder } from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_builder";
import {
  DESCRIPTION_COLUMN_CONFIG,
  DESCRIPTION_FILTER_CONFIG
} from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_config";
import { BASE_FILTER_CONFIG } from "../../columns/baseColumn/BaseColumn_config";
import { AutocompleteColumnBuilder } from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_builder";
import {
  AUTOCOMPLETE_COLUMN_CONFIG,
  AUTOCOMPLETE_FILTER_CONFIG,
  mapMilestoneTypes
} from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_config";
import {
  MILESTONE_TYPE_COLUMN_CONFIG,
  MILESTONE_TYPE_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/milestoneType/MilestoneTypeColumn_config";
import moment from "moment";

export interface MilestonesGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  columns: string[];
  milestoneTypes: any[];
  onFieldUpdate: () => Promise<any>;
}

export class MilestonesGridColumnBuilder extends BaseGridColumnBuilder {
  gridProps: MilestonesGridColumnBuilderProps;
  milestoneTypes: any[];
  columnDefs: Dictionary<() => ColDef>;

  constructor(gridProps: MilestonesGridColumnBuilderProps) {
    super(MilestonesApi.updateProjectMilestoneField, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.projectId = gridProps.projectId;
    this.milestoneTypes = gridProps.milestoneTypes;

    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Name]: () => this.buildNameColumn(),
      [CommonColDefFieldNamesEnum.Description]: () => this.buildDescriptionColumn(),
      [CommonColDefFieldNamesEnum.Deadline]: () => this.buildDeadlineColumn(),
      [MilestoneColDefFieldNamesEnum.MilestoneTypes]: () => this.buildMilestoneTypesColumn()
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];
    this.gridProps.columns.forEach(e => {
      if (e) {
        res.push(this.columnDefs[e]());
      }
    });

    return res;
  };

  buildNameColumn = () => {
    let model = new NameColumnBuilder()
      .setColumnOptions(NAME_COLUMN_CONFIG({ headerName: "Name" }))
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(NAME_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      // make cell editable
      model.createValueSetter(this.updateName);
    }

    return model.generateColumnOptions();
  };

  buildDescriptionColumn = () => {
    let model = new DescriptionColumnBuilder()
      .setColumnOptions(DESCRIPTION_COLUMN_CONFIG())
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(false)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(DESCRIPTION_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      model.setEditableOnDoubleClick(this.updateDescription);
    }

    return model.generateColumnOptions();
  };

  buildMilestoneTypesColumn = () => {
    const milestoneTypes = mapMilestoneTypes();
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions(
        AUTOCOMPLETE_COLUMN_CONFIG({
          field: MilestoneColDefFieldNamesEnum.MilestoneTypes,
          headerName: I18n.t("grids.milestoneType")
        })
      )
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG,
        ...MILESTONE_TYPE_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellEditor()
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.milestoneType = +params.newValue.key;
        this.updateMilestoneType(params.data.id, params.newValue.key);

        return true;
      })
      .withCellRenderer(params => {
        if (!params.data.milestoneType || !milestoneTypes) return null;
        const milestoneType = milestoneTypes.find(e => e.key === params.data.milestoneType + "");

        if (!milestoneType) return "";
        return <>{milestoneType.label}</>;
      })
      .setColumnOptions(MILESTONE_TYPE_COLUMN_CONFIG(milestoneTypes));

    return model.generateColumnOptions();
  };

  buildDeadlineColumn = () => {
    let model = new DateColumnBuilder()
      .setColumnOptions(
        DATE_COLUMN_CONFIG({ field: CommonColDefFieldNamesEnum.Deadline, headerName: I18n.t("grids.deadline") })
      )
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withComparator()
      .withCellEditor(CommonColDefFieldNamesEnum.Deadline, "")
      .setValueFormatter(CommonColDefFieldNamesEnum.Deadline)
      .setValueGetterByFieldFn(CommonColDefFieldNamesEnum.Deadline)
      .setFilterOptions(DATE_FILTER_CONFIG)
      .withCellRenderer(e => {
        if (e.data.deadline === "0001-01-01T00:00:00") return "";
        return moment(e.data.deadline).format("L");
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;

        let newDate = stringOrMomentToDate(params.newValue);
        let oldDate = stringToDate(params.oldValue);

        if (areDatesEqual(newDate, oldDate) === false) {
          this.updateEndDate(params.data.id, newDate);
          return true;
        }
        return false;
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  updateDescription = async (entityId: number, text: string) => {
    await this.updateTextField(MilestoneField.description, entityId, text);
    await this.gridProps.onFieldUpdate();
  };
  updateName = async (entityId: number, text: string) => {
    await this.updateTextField(MilestoneField.name, entityId, text);
    await this.gridProps.onFieldUpdate();
  };
  updateEndDate = async (entityId: number, date: Date) => {
    await this.updateDateField(MilestoneField.deadline, entityId, date);
    await this.gridProps.onFieldUpdate();
  };
  updateMilestoneType = async (entityId: number, data: any) => {
    await this.updateIdField(MilestoneField.milestoneType, entityId, data);
  };
}
