import { ColDef } from "ag-grid-community";
import { ColumnStateAddon } from "../base/addons/ColumnStateAddon";
import { FilterStoreAddon } from "../base/addons/FilterStoreAddon";
import { TextWrapperAddon } from "../base/addons/TextWrapAddon";
import { action, makeObservable, observable } from "mobx";
import { AppGridState } from "../base/AppGrid_view";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { EntityTypes } from "../../../../../../enums";
import { GetMilestoneGridActions } from "./MilestonesGridView_actions";
import { SingleFormModel } from "../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import I18n from "../../../../../localization/I18n";
import { getEntityNameMicroFormFields } from "../../../../../../pages/change/forms/microForm/getEntityNameMicroFormFields";
import { CommonColDefFieldNamesEnum, MilestoneColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { MilestonesGridColumnBuilder } from "./MilestonesGridView_columns";
import { TOASTER_TOAST_TIME } from "../../../../../toaster/Toaster_model";
import ProgressIndicatorModel, {
  IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import ToasterService, { IToasterService } from "../../../../../toaster/ToasterService";
import MilestonesApi, {
  MilestonesApi as IMilestonesApi
} from "../../../../../../services/api/v2/milestones/Milestones.api";
import { SHOW_MILESTONE_DELETE_CONFIRM_MODAL } from "./MilestonesGridView_modals";

export class MilestonesGridViewModel {
  organisationId: number;
  projectId: number;
  authUser: FP.Entities.IUser;
  @observable isLoading: boolean = true;
  @observable selectedItems: number[] = [];
  @observable.ref gridActions: IGridUiAction[] = [];
  @observable isFilterChanged: boolean;
  @observable isColumnStateChanged: boolean;
  filterStoreAddon: FilterStoreAddon;
  columnStateAddon: ColumnStateAddon;
  microMilestoneForm: SingleFormModel;
  textWrapAddon: TextWrapperAddon;
  gridColumns: ColDef<any, any>[];
  milestonesGridColumnBuilder: any;
  toasterService: IToasterService;
  httpProgress: IProgressIndicatorModel;
  milestonesProvider: IMilestonesApi;
  loadMilestones: () => Promise<any>;

  constructor(
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    loadMilestones: () => Promise<any>
  ) {
    makeObservable(this);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.httpProgress = ProgressIndicatorModel;
    this.milestonesProvider = MilestonesApi;
    this.toasterService = ToasterService;
    this.setMicroAddForm();
    this.loadMilestones = loadMilestones;
    this.generateAddons();
  }

  @action
  onMount = async () => {
    this.generateFn();
    this.setIsLoading(false);
  };

  @action
  generateFn = () => {
    this.generateGridConfig();
    this.generateActions();
  };

  generateGridConfig = () => {
    this.milestonesGridColumnBuilder = new MilestonesGridColumnBuilder({
      canEdit: true,
      organisationId: this.organisationId,
      projectId: this.projectId,
      onFieldUpdate: this.loadMilestones,
      milestoneTypes: [], //this.milestoneTypes,
      columns: [
        CommonColDefFieldNamesEnum.Selected,
        CommonColDefFieldNamesEnum.Name,
        CommonColDefFieldNamesEnum.Description,
        MilestoneColDefFieldNamesEnum.Deadline,
        MilestoneColDefFieldNamesEnum.MilestoneTypes
        // CommonColDefFieldNamesEnum.CreatedBy,
        // CommonColDefFieldNamesEnum.CreatedAt,
        // CommonColDefFieldNamesEnum.ModifiedBy,
        // CommonColDefFieldNamesEnum.UpdatedAt
      ]
    });
    this.gridColumns = this.milestonesGridColumnBuilder.generateColumnDefs();
  };

  @action
  generateAddons = () => {
    this.textWrapAddon = new TextWrapperAddon(this.projectId, EntityTypes.MILESTONES);
    this.filterStoreAddon = new FilterStoreAddon({
      projectId: this.projectId,
      gridType: EntityTypes.MILESTONES,
      filterHasChangedFn: this.setIsFilterChanged
    });
    this.columnStateAddon = new ColumnStateAddon({
      projectId: this.projectId,
      gridType: EntityTypes.MILESTONES,
      columnOrderHasChangedFn: this.setIsColumnStateChanged
    });
  };

  @action
  setMicroAddForm = () => {
    this.microMilestoneForm = new SingleFormModel();
    this.microMilestoneForm.formFields = getEntityNameMicroFormFields(
      this.createMicroMilestone,
      I18n.t("placeholders.myNewName", { entity: I18n.t("entities.milestone") })
    );
  };

  @action
  createMicroMilestone = async () => {
    let microMilestoneFormRes = await this.microMilestoneForm.submit();
    this.microMilestoneForm.isSaving = true;
    if (!microMilestoneFormRes) return;

    microMilestoneFormRes = { ...microMilestoneFormRes, projectId: this.projectId, impacts: [], tags: [] };

    const res = {
      ...microMilestoneFormRes
    };
    this.httpProgress.showOverlay();
    const result = await this.milestonesProvider.createProjectMilestone(
      this.organisationId,
      this.projectId,
      res as FP.Entities.IMilestone
    );
    await this.loadMilestones();
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;

    const action = result.payload;
    this.microMilestoneForm.resetFields();
    this.toasterService
      .showSuccessToast()
      .setContent(<span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.action") })}</span>)
      .startTimer(TOASTER_TOAST_TIME.NORMAL);

    return action;
  };

  showMilestoneConfirmDeleteModal = () => {
    return SHOW_MILESTONE_DELETE_CONFIRM_MODAL(this.selectedItems, this.removeItems);
  };

  removeItems = async (itemIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.milestonesProvider.deleteProjectMilestonesRange(this.organisationId, this.projectId, itemIds);
    await this.loadMilestones();
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setGridActions = (gridActions: any) => {
    this.gridActions = gridActions;
  };

  @action
  onGridStateUpdate = (gridState: AppGridState) => {
    this.selectedItems = gridState.selectedItems || [];
    this.generateActions();
  };

  @action
  setIsColumnStateChanged = (isColumnStateChanged: boolean) => {
    this.isColumnStateChanged = isColumnStateChanged;
    this.generateActions();
  };

  @action
  setIsFilterChanged = (isFilterChanged: boolean) => {
    this.isFilterChanged = isFilterChanged;
    this.generateActions();
  };

  generateActions = () => {
    this.setGridActions(GetMilestoneGridActions(this));
  };
}
