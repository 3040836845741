import { action, makeObservable, observable } from "mobx";
import ModalContext from "../../../../../../../core/modalZ/context/ModalContext";
import { BaseModel } from "../../../../../../../core/util/BaseModel";
import { StakeholderType } from "../../../../../../../enums";
import Pages from "../../../../../../../routes/InsightRoutes";
import StakeholdersApi, {
  StakeholdersApi as IStakeholdersApi
} from "../../../../../../../services/api/v2/stakeholders/Stakeholders.api";
import { PieDataObject } from "../../../Heatmap/Heatmap_utils";
import { getBasenameAsPrefix } from "../../../../../../../core/util/Helpers";
import { UrlOperators } from "../../../../../../../core/grids/builder/directorBuilder/grids/base/addons/FilterStoreAddon";
import AudiencesApi, {
  AudiencesApi as IAudiencesApi
} from "../../../../../../../services/api/v2/audiences/Audiences.api";
import {
  CommonColDefFieldNamesEnum,
  ImpactColDefFieldNamesEnum
} from "../../../../../../../core/grids/builder/enums/AgGridColDefFieldNameEnum";

export class ImpactCountSidebarModel extends BaseModel {
  stakeholderProvider: IStakeholdersApi;
  audienceProvider: IAudiencesApi;
  @observable isLoading = true;
  @observable.ref drillDownPieData: PieDataObject[];
  stakeholderId: number;
  stakeholderName: string;
  stakeholderType: StakeholderType;
  organisationId: number;
  pieColors: string[];
  basename: string;

  constructor(
    organisationId: number,
    stakeholderId: number,
    stakeholderName: string,
    stakeholderType: StakeholderType,
    basename: string
  ) {
    super();
    makeObservable(this);
    this.stakeholderProvider = StakeholdersApi;
    this.audienceProvider = AudiencesApi;
    this.stakeholderId = stakeholderId;
    this.stakeholderName = stakeholderName;
    this.stakeholderType = stakeholderType;
    this.organisationId = organisationId;
    this.basename = basename;
    this.pieColors = [
      "#fdd886",
      "#ffc036",
      "#feae95",
      "#f49d4e",
      "#dc9700",
      "#f7820d",
      "#cc660a",
      "#966700",
      "#ed7650",
      "#ac5337",
      "#8a4100",
      "#452015"
    ];
  }

  onMount = async () => {
    if (this.stakeholderType === StakeholderType.INDIVIDUAL) {
      await this.loadPieDataForStakeholders();
    }
    if (this.stakeholderType === StakeholderType.AUDIENCE) {
      await this.loadPieDataForAudiences();
    }
  };

  loadPieDataForStakeholders = async () => {
    const stakeholderProjects = await this.stakeholderProvider.getStakeholderProjectsWithImpacts(
      this.organisationId,
      this.stakeholderId
    );

    const res = stakeholderProjects.payload.map(stkProj => {
      return {
        id: stkProj.id,
        label: stkProj.label,
        value: stkProj.value,
        color: ""
      };
    });

    this.setModalPieData(res);
    return true;
  };

  loadPieDataForAudiences = async () => {
    const audiencesProjects = await this.audienceProvider.getAudienceProjectsWithImpacts(
      this.organisationId,
      this.stakeholderId
    );

    const res = audiencesProjects.payload.map(stkProj => {
      return {
        id: stkProj.id,
        label: stkProj.label,
        value: stkProj.value,
        color: ""
      };
    });

    this.setModalPieData(res);
    return true;
  };

  getPieColors = () => {
    const result = [...this.pieColors];
    const zeroIndexHasValue = this.drillDownPieData[0].value > 0;
    const zeroIndexIsNoAccessProject = this.drillDownPieData[0].label === "Projects with no access";
    if (zeroIndexHasValue && zeroIndexIsNoAccessProject) result.unshift("#E5E5E5");
    return result;
  };

  @action
  setModalPieData = (data: PieDataObject[]) => {
    this.drillDownPieData = data;
    this.isLoading = false;
  };

  onPieClick = onClickData => {
    window.location.href = `${getBasenameAsPrefix(this.basename)}${Pages.projects.impacts.listView.generateLink(
      this.organisationId,
      onClickData.id
    )}/?${
      this.stakeholderType === 1 ? ImpactColDefFieldNamesEnum.ProjectStakeholders : ImpactColDefFieldNamesEnum.Audiences
    }=${UrlOperators.contains.symbol}${this.stakeholderName}&${
      CommonColDefFieldNamesEnum.ProgressStatus
    }=Not%20Started|In%20Progress`;
    ModalContext.hide();
  };
}
