import { ISdColDef } from "../../baseColumn/BaseColumnTypes";
import { BASE_COLUMN_CONFIG } from "../../baseColumn/BaseColumn_config";

export const SELECTION_COLUMN_CONFIG = (): ISdColDef<any, any> => ({
  ...BASE_COLUMN_CONFIG,
  field: "selected",
  headerName: "",
  width: 50,
  resizable: false,
  pinned: "left",
  editable: true,
  checkboxSelection: true,
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true
});
