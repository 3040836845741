import { GridApi } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../contexts/permissions/PermissionsTypes";
import { EntityTypes, Enums, GridTypes } from "../../../../../../enums";
import { getEntityNameMicroFormFields } from "../../../../../../pages/change/forms/microForm/getEntityNameMicroFormFields";
import { SingleFormModel } from "../../../../../../pages/change/forms/singleFormModel/SingleForm_model";
import { SHOW_CONFIRM_CREATION_MODAL } from "../../../../../../pages/insight/projects/Actions/ActionListView/ActionListView_modals";
import ImpactGroupsApi, {
  ImpactGroupsApi as IImpactGroupsApi
} from "../../../../../../services/api/v2/impactGroups/ImpactGroups.api";
import I18n from "../../../../../localization/I18n";
import { IModalContextModel } from "../../../../../modalZ/context/IModalContext";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../../toaster/ToasterService";
import { TOASTER_TOAST_TIME } from "../../../../../toaster/Toaster_model";
import { CommonColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { GridModalBuilder } from "../../modals/GridModal_builder";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { AppGridState } from "../base/AppGrid_view";
import { ColumnStateAddon } from "../base/addons/ColumnStateAddon";
import { FilterStoreAddon } from "../base/addons/FilterStoreAddon";
import { TextWrapperAddon } from "../base/addons/TextWrapAddon";
import { GetImpactGroupGridActions } from "./ImpactGroupsGrid_actions";
import { ImpactGroupsGridColumnBuilder } from "./ImpactGroupsGrid_columns";
import {
  LINK_TO_IMPACTS_MODAL_CONFIG,
  SHOW_IMPACT_GROUP_DELETE_CONFIRM_MODAL,
  SHOW_IMPACT_GROUP_REVIEW_CONFIRM_MODAL
} from "./ImpactGroupsGrid_modals";

export class ImpactGroupsGridViewModel {
  organisationId: number;
  projectId: number;
  @observable isLoading: boolean = false;
  progressStatuses: FP.Generic.IKeyLabel[];
  impactGroupsProvider: IImpactGroupsApi;
  authUser: FP.Entities.IUser;
  filterStoreAddon: FilterStoreAddon;
  columnStateAddon: ColumnStateAddon;
  textWrapAddon: TextWrapperAddon;
  @observable isFilterChanged: boolean;
  @observable isColumnStateChanged: boolean;
  userCanViewImpacts: boolean;
  userCanEditImpacts: boolean;
  type: GridTypes = GridTypes.IMPACT_ACTION_GRID;
  impactGroupGridColumnBuilder: ImpactGroupsGridColumnBuilder;
  modalService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  @observable confirmationService: IModalContextModel;
  @observable selectedItems: number[] = [];
  toasterService: IToasterService;
  @observable isDuplicatingItems: boolean = false;
  microImpactGroupForm: SingleFormModel;
  gridApi: GridApi;
  @observable.ref gridActions: IGridUiAction[] = [];
  gridModalBuilder: GridModalBuilder;

  constructor(organisationId: number, projectId: number, authUser: FP.Entities.IUser) {
    makeObservable(this);
    this.impactGroupsProvider = ImpactGroupsApi;
    this.userCanViewImpacts = PermissionsContext.canViewField(PermissionFields.IMPACTS, organisationId, projectId);
    this.userCanEditImpacts = PermissionsContext.canEditField(PermissionFields.IMPACTS, organisationId, projectId);
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.modalService = ModalContext;
    this.toasterService = ToasterService;
    this.confirmationService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.setMicroAddForm();
    this.gridModalBuilder = new GridModalBuilder();
    this.textWrapAddon = new TextWrapperAddon(this.projectId, EntityTypes.IMPACT_GROUPS);
    this.filterStoreAddon = new FilterStoreAddon({
      projectId: this.projectId,
      gridType: EntityTypes.IMPACT_GROUPS,
      filterHasChangedFn: this.setIsFilterChanged
    });
    this.columnStateAddon = new ColumnStateAddon({
      projectId: this.projectId,
      gridType: EntityTypes.IMPACT_GROUPS,
      columnOrderHasChangedFn: this.setIsColumnStateChanged
    });
    this.impactGroupGridColumnBuilder = new ImpactGroupsGridColumnBuilder({
      canEdit: true,
      organisationId: this.organisationId,
      projectId: this.projectId,
      userCanViewImpacts: this.userCanViewImpacts,
      columns: [
        CommonColDefFieldNamesEnum.Selected,
        CommonColDefFieldNamesEnum.Name,
        CommonColDefFieldNamesEnum.Description,
        CommonColDefFieldNamesEnum.Impacts,
        CommonColDefFieldNamesEnum.NoteCount
      ]
    });
    this.generateActions();
  }

  generateActions = () => {
    this.setGridActions(GetImpactGroupGridActions(this));
  };

  @action
  setGridActions = (gridActions: any) => {
    this.gridActions = gridActions;
  };

  @action
  onGridStateUpdate = (gridState: AppGridState) => {
    this.gridApi = gridState.gridApi;
    this.selectedItems = gridState.selectedItems || [];
  };

  @action
  setIsColumnStateChanged = (isColumnStateChanged: boolean) => {
    this.isColumnStateChanged = isColumnStateChanged;
    this.generateActions();
  };

  @action
  setIsFilterChanged = (isFilterChanged: boolean) => {
    this.isFilterChanged = isFilterChanged;
    this.generateActions();
  };

  getColumnConfig = () => {
    return this.impactGroupGridColumnBuilder.generateColumnDefs();
  };

  showLinkToImpacts = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(LINK_TO_IMPACTS_MODAL_CONFIG(this.selectedItems, this.assignImpactGroupsToImpacts))
      .generateModal();
  };

  duplicateItems = async () => {
    this.httpProgress.showTopProgressBarVisible();
    this.isDuplicatingItems = true;
    await this.impactGroupsProvider.duplicateImpactGroups(this.organisationId, this.projectId, this.selectedItems);
    this.httpProgress.hideTopProgressBarVisible();
    this.isDuplicatingItems = false;
  };

  showImpactConfirmReviewModal = () => {
    return SHOW_IMPACT_GROUP_REVIEW_CONFIRM_MODAL(this.selectedItems, this.reviewItems, this.toasterService, () =>
      this.gridApi.deselectAll()
    );
  };

  showImpactConfirmDeleteModal = () => {
    return SHOW_IMPACT_GROUP_DELETE_CONFIRM_MODAL(this.selectedItems, this.removeItems);
  };

  reviewItems = async (itemIds: number[], comment: string) => {
    let res = await this.impactGroupsProvider.reviewRange(this.organisationId, this.projectId, itemIds, comment);

    if (!res || res.isError) return;
  };

  removeItems = async (itemIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.impactGroupsProvider.deleteRange(this.organisationId, this.projectId, itemIds);
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };

  exportParams = () => {
    return {
      onlySelected: true,
      fileName: "insight-impact-group-export.csv"
    };
  };

  @action
  exportRows = () => {
    if (this.selectedItems && this.selectedItems.length > 0) {
      if (this.gridApi !== undefined) this.gridApi.exportDataAsCsv(this.exportParams());
    }
  };

  @action
  setMicroAddForm = () => {
    this.microImpactGroupForm = new SingleFormModel();
    this.microImpactGroupForm.formFields = getEntityNameMicroFormFields(
      this.createMicroImpactGroup,
      I18n.t("placeholders.myNewName", { entity: I18n.t("entities.impactGroup") })
    );
  };

  @action
  createMicroImpactGroup = async () => {
    let impactGroupFormRes = await this.microImpactGroupForm.submit();
    this.microImpactGroupForm.isSaving = true;
    if (!impactGroupFormRes) return;

    impactGroupFormRes = {
      ...impactGroupFormRes,
      projectId: this.projectId,
      tags: [],
      impactTypes: [],
      changeBenefitText: ""
    };

    this.httpProgress.showOverlay();
    let impactGroupExists = await this.impactGroupsProvider.getFiltered(this.organisationId, this.projectId, {
      filters: `name==${impactGroupFormRes.name},lifecycleStatus==${Enums.LifecycleStatus.Active},projectId==${this.projectId}`
    });
    if (impactGroupExists && !impactGroupExists.isError && impactGroupExists.payload.length) {
      this.httpProgress.hideOverlay();
      let confirmCreateImpact = await this.confirmCreateImpact(impactGroupFormRes.name);
      if (!confirmCreateImpact) return;
      this.httpProgress.showOverlay();
    }

    const res = {
      ...impactGroupFormRes
    };

    this.httpProgress.showOverlay();
    const result = await this.impactGroupsProvider.create(
      this.organisationId,
      this.projectId,
      res as FP.Entities.IImpact
    );
    this.httpProgress.hideOverlay();

    if (!result || result.isError) return;

    const impact = result.payload;
    this.microImpactGroupForm.resetFields();
    this.toasterService
      .showSuccessToast()
      .setContent(<span>{I18n.t("phrases.itemCreatedSuccessfully", { item: I18n.t("entities.impact") })}</span>)
      .startTimer(TOASTER_TOAST_TIME.NORMAL);

    return impact;
  };

  confirmCreateImpact = async (name: string): Promise<boolean> => {
    return SHOW_CONFIRM_CREATION_MODAL(this.modalService, name);
  };

  assignImpactGroupsToImpacts = async impactIds => {
    await this.impactGroupsProvider.addBulkImpacts(this.organisationId, this.projectId, this.selectedItems, impactIds);
    this.modalService.hide();
  };
}
