import { ColDef, GridApi } from "ag-grid-community";
import { action, makeObservable, observable } from "mobx";
import ProgressIndicatorModel, {
  IProgressIndicatorModel
} from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import PermissionsContext from "../../../../../../contexts/permissions/PermissionsContext";
import { PermissionFields } from "../../../../../../contexts/permissions/PermissionsTypes";
import { EntityTypes, ProjectStakeholderField, StakeholderType } from "../../../../../../enums";
import ProjectStakeholdersApi, {
  ProjectStakeholdersApi as IProjectStakeholdersApi
} from "../../../../../../services/api/v2/projectStakeholders/ProjectStakeholders.api";
import ProjectTeamUserPermissionsApi, {
  ProjectTeamUserPermissionsApi as IProjectTeamUserPermissionsApi
} from "../../../../../../services/api/v2/projectTeamUserPermissions/ProjectTeamUserPermissions.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import { IGridToastService } from "../../../../../../services/local/gridToastService/IGridToastService";
import { IModalContextModel } from "../../../../../modalZ/context/IModalContext";
import ModalContext from "../../../../../modalZ/context/ModalContext";
import ToasterService, { IToasterService } from "../../../../../toaster/ToasterService";
import { GridModalBuilder } from "../../modals/GridModal_builder";
import { IGridUiAction } from "../base/AppGridToolbarActions_view";
import { AppGridState } from "../base/AppGrid_view";
import { ColumnStateAddon } from "../base/addons/ColumnStateAddon";
import { FilterStoreAddon } from "../base/addons/FilterStoreAddon";
import { TextWrapperAddon } from "../base/addons/TextWrapAddon";
import { ProjectStakeholdersGridColumnBuilder } from "./ProjectStakeholdersGrid_columns";
import {
  PROJECT_STAKEHOLDER_LINK_TO_ACTIONS_MODAL_CONFIG,
  SHOW_PROJECT_STAKEHOLDER_DELETE_CONFIRM_MODAL,
  SHOW_PROJECT_STAKEHOLDER_REVIEW_CONFIRM_MODAL,
  PROJECT_STAKEHOLDER_BULK_EDIT_MODAL_CONFIG,
  STAKEHOLDER_LINK_MODAL_CONFIG,
  PROJECT_STAKEHOLDER_LINK_TO_IMPACT_LINK_MODAL
} from "./ProjectStakeholdersGrid_modals";
import { AppGridToolbarType } from "../../types/AppGrid_types";
import { GetProjectStakeholdersGridActions } from "./ProjectStakeholdersGrid_actions";
import { STAKEHOLDER_EMAILS_MODAL_CONFIG } from "./modals/stakeholderEmailsModal/StakeholderEmails_config";
import ActionsApi, { ActionsApi as IActionsApi } from "../../../../../../services/api/v2/actions/Actions.api";
import I18n from "../../../../../localization/I18n";
import { PROJECT_STAKEHOLDER_NOTES_SIDEBAR_MODAL_CONFIG } from "./modals/noteCountSidebar/ProjectStakeholderNotesSidebarModal_config";

export class ProjectStakeholdersGridModel {
  organisationId: number;
  projectId: number;
  @observable isLoading: boolean = true;
  progressStatuses: FP.Generic.IKeyLabel[];
  actionsProvider: IActionsApi;
  projectStakeholderProvider: IProjectStakeholdersApi;
  authUser: FP.Entities.IUser;
  filterStoreAddon: FilterStoreAddon;
  columnStateAddon: ColumnStateAddon;
  textWrapAddon: TextWrapperAddon;
  @observable isFilterChanged: boolean;
  @observable isColumnStateChanged: boolean;
  userCanViewStakeholders: boolean;
  userCanEditStakeholders: boolean;
  projectStakeholdersGridColumnBuilder: ProjectStakeholdersGridColumnBuilder;
  modalService: IModalContextModel;
  httpProgress: IProgressIndicatorModel;
  @observable confirmationService: IModalContextModel;
  @observable selectedItems: number[] = [];
  toasterService: IToasterService;
  @observable isDuplicatingItems: boolean = false;
  gridApi: GridApi;
  @observable.ref gridActions: IGridUiAction[] = [];
  gridModalBuilder: GridModalBuilder;
  gridToastService: IGridToastService;
  projectTeamUserPermissionsProvider: IProjectTeamUserPermissionsApi = ProjectTeamUserPermissionsApi;
  projectTeamMembers: any[];
  gridColumns: ColDef<any, any>[];
  columnDefs: FP.Entities.IColumnDef[];
  urlSearchParams: URLSearchParams;
  gridToolbarType: AppGridToolbarType;

  constructor(
    organisationId: number,
    projectId: number,
    authUser: FP.Entities.IUser,
    columnDefs: FP.Entities.IColumnDef[],
    urlSearchParams: URLSearchParams,
    gridToolbarType: AppGridToolbarType
  ) {
    makeObservable(this);
    this.actionsProvider = ActionsApi;
    this.projectStakeholderProvider = ProjectStakeholdersApi;
    this.userCanViewStakeholders = PermissionsContext.canViewField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
    this.userCanEditStakeholders = PermissionsContext.canEditField(
      PermissionFields.STAKEHOLDERS,
      organisationId,
      projectId
    );
    this.organisationId = organisationId;
    this.projectId = projectId;
    this.authUser = authUser;
    this.urlSearchParams = urlSearchParams;
    this.gridToolbarType = gridToolbarType;
    this.modalService = ModalContext;
    this.toasterService = ToasterService;
    this.confirmationService = ModalContext;
    this.httpProgress = ProgressIndicatorModel;
    this.columnDefs = columnDefs;
    this.gridToastService = GridToastService;
    this.gridModalBuilder = new GridModalBuilder();
    this.generateAddons();
  }

  loadInitData = async () => {
    if (this.userCanEditStakeholders && this.gridToolbarType !== "link-modal") {
      await this.loadProjectTeamMembers();
      this.generateFn();
    }
  };

  @action
  onMount = async () => {
    this.generateFn();
    await this.loadInitData();
    this.setIsLoading(false);
  };

  @action
  generateFn = () => {
    this.generateGridConfig();
    this.generateActions();
  };

  onUnmount = () => {};

  generateAddons = () => {
    this.textWrapAddon = new TextWrapperAddon(this.projectId, EntityTypes.PROJECT_STAKEHOLDER);
    this.filterStoreAddon = new FilterStoreAddon({
      projectId: this.projectId,
      gridType: EntityTypes.PROJECT_STAKEHOLDER,
      filterHasChangedFn: this.setIsFilterChanged,
      urlSearchParams: this.urlSearchParams
    });
    this.columnStateAddon = new ColumnStateAddon({
      projectId: this.projectId,
      gridType: EntityTypes.PROJECT_STAKEHOLDER,
      columnOrderHasChangedFn: this.setIsColumnStateChanged
    });
  };

  generateGridConfig = () => {
    this.projectStakeholdersGridColumnBuilder = new ProjectStakeholdersGridColumnBuilder({
      canEdit: this.userCanEditStakeholders && this.gridToolbarType !== "link-modal",
      organisationId: this.organisationId,
      projectId: this.projectId,
      userCanViewStakeholders: this.userCanViewStakeholders,
      projectTeamMembers: this.projectTeamMembers,
      isKeyStakeholdersOptions: [
        { key: "0", label: I18n.t("phrases.no") },
        { key: "1", label: I18n.t("phrases.yes") }
      ],
      columns: this.columnDefs
    });
    this.gridColumns = this.projectStakeholdersGridColumnBuilder.generateColumnDefs();
  };

  generateActions = () => {
    this.setGridActions(GetProjectStakeholdersGridActions(this));
  };

  @action
  loadProjectTeamMembers = async () => {
    const res = await this.projectTeamUserPermissionsProvider.getAllUsersSimple(this.organisationId, this.projectId);
    if (!res || res.isError) return;
    this.setProjectTeamMembers(res.payload);
  };

  @action
  setProjectTeamMembers = projectTeamMembers => {
    this.projectTeamMembers = projectTeamMembers;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  @action
  setGridActions = (gridActions: any) => {
    this.gridActions = gridActions;
  };

  @action
  onGridStateUpdate = (gridState: AppGridState) => {
    this.gridApi = gridState.gridApi;
    this.selectedItems = gridState.selectedItems || [];
  };

  @action
  setIsColumnStateChanged = (isColumnStateChanged: boolean) => {
    this.isColumnStateChanged = isColumnStateChanged;
    this.generateActions();
  };

  @action
  setIsFilterChanged = (isFilterChanged: boolean) => {
    this.isFilterChanged = isFilterChanged;
    this.generateActions();
  };

  @action
  deleteFieldData = async (projectStakeholderId: number, stakeholderField: ProjectStakeholderField) => {
    const res = await this.projectStakeholderProvider.deleteField(
      this.organisationId,
      this.projectId,
      projectStakeholderId,
      stakeholderField
    );
    if (res.isError) return false;
    this.gridToastService.showToast(res.code, res.message);

    return true;
  };

  reviewItems = async (itemIds: number[], comment: string) => {
    let res = await this.projectStakeholderProvider.reviewRange(this.organisationId, this.projectId, itemIds, comment);

    if (!res || res.isError) return;
  };

  removeItems = async (itemIds: number[]) => {
    this.httpProgress.showOverlay();
    let res = await this.projectStakeholderProvider.deleteRange(this.organisationId, this.projectId, itemIds);
    this.httpProgress.hideOverlay();
    if (!res || res.isError) return;

    return res.payload;
  };

  exportParams = () => {
    return {
      onlySelected: true,
      fileName: "insight-stakeholders--export.csv"
    };
  };

  @action
  exportRows = () => {
    if (this.selectedItems && this.selectedItems.length > 0) {
      if (this.gridApi !== undefined) this.gridApi.exportDataAsCsv(this.exportParams());
    }
  };

  showLinkStakeholderModal = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(STAKEHOLDER_LINK_MODAL_CONFIG(this.projectId, StakeholderType.INDIVIDUAL))
      .generateModal();
  };

  showEmailsModal = () => {
    return this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(STAKEHOLDER_EMAILS_MODAL_CONFIG(this.selectedItems, this.projectId))
      .generateModal();
  };

  openNoteCountModal = (item: FP.Entities.IProjectStakeholderSummary) => {
    this.gridModalBuilder
      .constructSideModal()
      .setModalOptions(PROJECT_STAKEHOLDER_NOTES_SIDEBAR_MODAL_CONFIG(this.projectId, this.organisationId, item))
      .generateModal();
  };

  showLinkToImpacts = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(
        PROJECT_STAKEHOLDER_LINK_TO_IMPACT_LINK_MODAL(this.selectedItems, this.assignStakeholderToImpacts)
      )
      .generateModal();
  };

  showLinkToActions = () => {
    this.gridModalBuilder
      .constructPopupModal()
      .setModalOptions(
        PROJECT_STAKEHOLDER_LINK_TO_ACTIONS_MODAL_CONFIG(this.selectedItems, this.assignStakeholderToActions)
      )
      .generateModal();
  };

  showBulkEditModal = () => {
    this.gridModalBuilder
      .constructSideModal()
      .setModalOptions(
        PROJECT_STAKEHOLDER_BULK_EDIT_MODAL_CONFIG(this.projectId, this.organisationId, this.selectedItems)
      )
      .generateModal();
  };

  showProjectStakeholderConfirmReviewModal = () => {
    return SHOW_PROJECT_STAKEHOLDER_REVIEW_CONFIRM_MODAL(
      this.selectedItems,
      this.reviewItems,
      this.toasterService,
      () => this.gridApi.deselectAll()
    );
  };

  showProjectStakeholderConfirmDeleteModal = () => {
    return SHOW_PROJECT_STAKEHOLDER_DELETE_CONFIRM_MODAL(this.selectedItems, this.removeItems);
  };

  assignStakeholderToImpacts = async (impactIds: number[]) => {
    await this.projectStakeholderProvider.assignMultipleImpacts(
      this.organisationId,
      this.projectId,
      this.selectedItems,
      impactIds
    );
    this.modalService.hide();
  };

  assignStakeholderToActions = async (actionIds: number[]) => {
    await this.actionsProvider.assignStakeholderOwners(
      this.organisationId,
      this.projectId,
      actionIds,
      this.selectedItems
    );
    this.modalService.hide();
  };
}
