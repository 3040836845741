export enum HubEvents {
  INVOKE_USER_JOIN = "invokeUserJoin",
  INVOKE_USER_LEFT = "invokeUserLeft",
  INVOKE_LOAD_IMPACT_DATA = "InvokeImpactGridData",
  INVOKE_LOAD_IMPACT_GROUP_DATA = "InvokeImpactGroupGridData",
  INVOKE_LOAD_ACTION_DATA = "InvokeActionGridData",
  INVOKE_USER_CELL_SELECTED = "invokeUserCellSelected",
  INVOKE_LOAD_PROJECT_STAKEHOLDER_DATA = "InvokeProjectStakeholderGridData",
  INVOKE_LOAD_PROJECT_AUDIENCE_DATA = "InvokeProjectAudienceGridData",
  INVOKE_LOAD_BENEFIT_DATA = "InvokeBenefitGridData",
  INVOKE_LOAD_DATA = "InvokeData",
  INVOKE_LOAD_IMPACT_REPORT_BY_TYPE_DATA = "InvokeImpactReportByTypeData",
  INVOKE_LOAD_AUDIENCE_DATA = "InvokeAudienceGridData",
  INVOKE_LOAD_IMPACT_REPORT_BY_LOCATION_DATA = "InvokeImpactReportByLocationData",
  INVOKE_LOAD_IMPACT_REPORT_BY_BUSINESS_AREA_DATA = "InvokeImpactReportByBusinessAreaData",
  INVOKE_LOAD_IMPACT_REPORT_BY_OWNER_DATA = "InvokeImpactReportByOwnerData",
  INVOKE_LOAD_IMPACT_REPORT_BY_STAKEHOLDER_SENTIMENT_DATA = "InvokeImpactReportByStakeholderSentimentData",
  INVOKE_LOAD_IMPACT_REPORT_BY_STAKEHOLDER_COMMITMENT_DATA = "InvokeImpactReportByStakeholderCommitmentData",
  INVOKE_LOAD_IMPACT_REPORT_BY_STAKEHOLDER_RECEPTIVENESS_DATA = "InvokeImpactReportByStakeholderReceptivenessData",
  INVOKE_LOAD_STAKEHOLDER_UPLOAD_GRID_DATA = "InvokeLoadStakeholderUploadGridData",
  INVOKE_LOAD_STAKEHOLDER_UPLOAD_FILE_GRID_DATA = "InvokeLoadStakeholderUploadFileGridData",
  INVOKE_LOAD_STAKEHOLDER_ASSIGNMENT_METRICS_GRID_DATA = "InvokeLoadStakeholderAssignmentMetricsGridData",
  INVOKE_LOAD_AUDIENCE_ASSIGNMENT_METRICS_GRID_DATA = "InvokeLoadAudienceAssignmentMetricsGridData",
  INVOKE_LOAD_CHANGE_TEAM_UTILISATION_GRID_DATA = "InvokeLoadChangeTeamUtilisationGridData",
  INVOKE_LOAD_PROJECT_DASHBOARD_DATA = "InvokeProjectDashboardData",
  INVOKE_LOAD_PROCESSES_DATA = "InvokeProcessGridData",
  INVOKE_LOAD_PROJECT_PROCESSES_DATA = "InvokeProjectProcessGridData",
  INVOKE_LOAD_BUSINESS_PROCESSES_DATA = "InvokeBusinessProcessGridData",
  INVOKE_LOAD_PROJECT_JOB_ROLE_GRID_DATA = "InvokeProjectJobRoleGridData",

  ON_USER_JOIN = "onUserJoin",
  ON_LOAD_IMPACT_DATA = "OnImpactGridData",
  ON_LOAD_IMPACT_GROUP_DATA = "OnImpactGroupGridData",
  ON_USER_CELL_SELECTED = "onUserCellSelected",
  ON_LOAD_ACTION_DATA = "OnActionGridData",
  ON_LOAD_PROJECT_STAKEHOLDER_DATA = "OnProjectStakeholderGridData",
  ON_LOAD_PROJECT_AUDIENCE_DATA = "OnProjectAudienceGridData",
  ON_LOAD_AUDIENCE_DATA = "OnAudienceGridData",
  ON_LOAD_BENEFIT_DATA = "OnGridData",
  ON_DATA = "OnData",
  ON_IMPACTS_BY_TYPE_CHART_DATA = "OnImpactsByTypeChartData",
  ON_IMPACTS_BY_LOCATION_CHART_DATA = "OnImpactsByLocationChartData",
  ON_IMPACTS_BY_BUSINESS_AREA_CHART_DATA = "OnImpactsByBusinessAreaChartData",
  ON_IMPACTS_BY_OWNER_CHART_DATA = "OnImpactsByOwnerChartData",
  ON_IMPACTS_BY_STAKEHOLDER_SENTIMENT_CHART_DATA = "OnImpactsByStakeholderSentimentChartData",
  ON_IMPACTS_BY_STAKEHOLDER_COMMITMENT_CHART_DATA = "OnImpactsByStakeholderCommitmentChartData",
  ON_IMPACTS_BY_STAKEHOLDER_RECEPTIVENESS_CHART_DATA = "OnImpactsByStakeholderReceptivenessChartData",
  ON_STAKEHOLDER_UPLOAD_GRID_DATA = "OnStakeholderUploadGridData",
  ON_STAKEHOLDER_UPLOAD_FILE_GRID_DATA = "OnStakeholderUploadFileGridData",
  ON_STAKEHOLDER_ASSIGNMENT_METRICS_GRID_DATA = "OnStakeholderAssignmentMetricsGridData",
  ON_AUDIENCE_ASSIGNMENT_METRICS_GRID_DATA = "OnAudienceAssignmentMetricsGridData",
  ON_CHANGE_TEAM_UTILISATION_GRID_DATA = "OnChangeTeamUtilisationGridData",
  ON_LOAD_PROJECT_DASHBOARD_DATA = "OnDataLoad",
  ON_LOAD_PROCESSES_DATA = "OnProcessGridData",
  ON_LOAD_PROJECT_PROCESSES_DATA = "OnProjectProcessGridData",
  ON_LOAD_BUSINESS_PROCESSES_DATA = "OnBusinessProcessGridData",
  ON_LOAD_PROJECT_JOB_ROLE_DATA = "OnProjectJobRolesGridData"
}
