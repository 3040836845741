import { ICellEditorParams } from "ag-grid-community";
import moment from "moment";
import { useEffect, useImperativeHandle, useState } from "react";
import { forwardRef } from "react";
import { SingleDatePicker } from "../../../components/ui/_forms/SingleDatePicker";
import moment2 from "moment";
import { defaultDateTimeString } from "../../../enums";

export default forwardRef((props: ICellEditorParams, ref) => {
  const [editing, setEditing] = useState(true);
  const { field, defaultDate, onDatepickerClick } = props as any;
  const actualDefaultDate = props.data[field]
    ? props.data[field] === defaultDateTimeString
      ? defaultDate
      : props.data[field]
    : defaultDate;

  const [date, setDate] = useState(
    !props.data[field] || (props.data[field] && props.data[field] === defaultDateTimeString)
      ? actualDefaultDate
      : props.data[field]
  );
  const initDateValue = actualDefaultDate && props.data[actualDefaultDate] ? props.data[actualDefaultDate] : new Date();

  useEffect(() => {
    if (!editing) {
      props.api.stopEditing();
    }
  }, [editing, props.api]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return date;
      }
    };
  });

  return (
    <div className="calendar">
      <SingleDatePicker
        datePickerProps={{
          id: "key",
          date: moment(date || initDateValue),
          isOutsideRange: day => {
            if (!date && initDateValue === props.data[actualDefaultDate]) return day.isBefore(initDateValue);
            return false;
          },
          numberOfMonths: 1,
          openDirection: props.rowIndex < 6 ? "down" : "up",
          readOnly: true,
          placeholder: "Select Date",
          focused: true,
          renderMonthElement: renderMonthElement,
          onDateChange: e => {
            setDate(!!onDatepickerClick ? onDatepickerClick(props, e) : e);
            setEditing(false);
          }
        }}
      />
    </div>
  );
});

const DATE_YEAR_RANGE = 10;

const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <div>
      <select
        value={month.month()}
        onChange={e => onMonthSelect(month, e.target.value)}
        className="DateRangePicker_monthSelect"
      >
        {moment.months().map((label, value) => (
          <option value={value}>{label}</option>
        ))}
      </select>
    </div>
    <div>
      <select
        value={month.year()}
        onChange={e => onYearSelect(month, e.target.value)}
        className="DateRangePicker_yearSelect"
      >
        {yearOptions(DATE_YEAR_RANGE)}
      </select>
    </div>
  </div>
);

const yearOptions = (numberOfYears: number) => {
  var rows = [];
  let [start, target] = getYears(numberOfYears);
  const startDate = moment2().year() + start;
  const endDate = moment2().year() + target;

  const date = moment2();
  if (date.year() < startDate || date.year() > endDate) {
    rows.push(<option value={date.year()}>{date.year()}</option>);
  }

  for (var i = start; i < target; i++) {
    rows.push(<option value={moment2().year() + i}>{moment2().year() + i}</option>);
  }

  return rows;
};

const getYears = numberOfYears => {
  var start = (numberOfYears / 2) * -1;
  var target = numberOfYears / 2 + 1;
  return [start, target];
};
