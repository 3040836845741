import { ColDef, ICellEditorParams } from "ag-grid-community";
import _ from "lodash";
import moment from "moment";
import ProgressIndicatorModel from "../../../../../../components/widgets/ProgressIndicator/ProgressIndicator_model";
import { Enums, defaultDateTimeString } from "../../../../../../enums";
import Pages from "../../../../../../routes/InsightRoutes";
import CustomFieldsApi from "../../../../../../services/api/v2/customFields/CustomFields.api";
import ImpactsApi, { ImpactsApi as IImpactsApi } from "../../../../../../services/api/v2/impacts/Impacts.api";
import GridToastService from "../../../../../../services/local/gridToastService/GridToastService";
import I18n from "../../../../../localization/I18n";
import { laterDateComparator } from "../../../../GridHelpers";
import { CommonColDefFieldNamesEnum, ImpactColDefFieldNamesEnum } from "../../../enums/AgGridColDefFieldNameEnum";
import { BASE_FILTER_CONFIG } from "../../columns/baseColumn/BaseColumn_config";
import { AutocompleteColumnBuilder } from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_builder";
import {
  AUTOCOMPLETE_COLUMN_CONFIG,
  AUTOCOMPLETE_FILTER_CONFIG,
  mapImpactLevels,
  mapProgressStatuses
} from "../../columns/commonColumns/AutocompleteColumn/AutocompleteColumn_config";
import {
  OWNER_NAME_COLUMN_CONFIG,
  OWNER_NAME_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/ownerName/OwnerNameColumn_config";
import {
  PROGRESS_STATUS_COLUMN_CONFIG,
  PROGRESS_STATUS_FILTER_CONFIG
} from "../../columns/commonColumns/AutocompleteColumn/progressStatus/ProgressStatusColumn_config";
import { DateColumnBuilder } from "../../columns/commonColumns/DateColumn/DateColumn_builder";
import { DATE_COLUMN_CONFIG, DATE_FILTER_CONFIG } from "../../columns/commonColumns/DateColumn/DateColumn_config";
import { DescriptionColumnBuilder } from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_builder";
import { DESCRIPTION_COLUMN_CONFIG, DESCRIPTION_FILTER_CONFIG } from "../../columns/commonColumns/DescriptionColumn/DescriptionColumn_config";
import { DynamicColumnBuilder } from "../../columns/commonColumns/DynamicColumn/DynamicColumn_builder";
import { DYNAMIC_COLUMN_CONFIG } from "../../columns/commonColumns/DynamicColumn/DynamicColumn_config";
import { NameColumnBuilder } from "../../columns/commonColumns/NameColumn/NameColumn_builder";
import { NAME_COLUMN_CONFIG, NAME_FILTER_CONFIG } from "../../columns/commonColumns/NameColumn/NameColumn_config";
import { NoteCountColumnBuilder } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_builder";
import { NOTE_COUNT_COLUMN_CONFIG } from "../../columns/commonColumns/NoteCountColumn/NoteCountColumn_config";
import { RefNumberColumnBuilder } from "../../columns/commonColumns/RefNumberColumn/RefNumberColumn_builder";
import {
  REF_NUMBER_COLUMN_CONFIG,
  REF_NUMBER_FILTER_CONFIG
} from "../../columns/commonColumns/RefNumberColumn/RefNumberColumn_config";
import { SelectionColumnBuilder } from "../../columns/commonColumns/SelectionColumn/SelectionColumn_builder";
import { ImpactField } from "../../utils/GridFields";
import {} from "../ImpactGroupsGrid/modals/noteCountModal/ImpactGroupNotesSidebar";
import { BaseGridColumnBuilder } from "../base/BaseGridColumnBuilder";
import { IMPACT_AUDIENCES_SIDEBAR_MODAL_CONFIG } from "./ImpactsGrid_modals";
import {
  IMPACT_LEVEL_COLUMN_CONFIG,
  IMPACT_LEVEL_FILTER_CONFIG
} from "./columns/ImpactLevelColumn/ImpactLevelColumn_config";
import { TotalPeopleImpactedColumnBuilder } from "./columns/TotalPeopleImpactedColumn/TotalPeopleImpactedColumn_builder";
import {
  TOTAL_PEOPLE_IMPACTED_COLUMN_CONFIG,
  TOTAL_PEOPLE_IMPACTED_FILTER_CONFIG
} from "./columns/TotalPeopleImpactedColumn/TotalPeopleImpactedColumn_config";
import { IMPACT_ACTIONS_SIDEBAR_MODAL_CONFIG } from "./modals/impactActionSidebar/ImpactActionsSidebarModal_config";
import { IMPACT_BUSINESS_AREAS_SIDEBAR_MODAL_CONFIG } from "./modals/impactBusinessAreasSidebar/ImpactBusinessAreasSidebarModal_config";
import { IMPACT_IMPACT_GROUPS_SIDEBAR_MODAL_CONFIG } from "./modals/impactGroupsSidebar/ImpactGroupsSidebarModal_config";
import { IMPACT_LOCATIONS_SIDEBAR_MODAL_CONFIG } from "./modals/impactLocationsSidebar/ImpactLocationsSidebarModal_config";
import { IMPACT_MILESTONES_SIDEBAR_MODAL_CONFIG } from "./modals/impactMilestonesSidebar/ImpactMilestonesSidebar_config";
import { IMPACT_PROCESS_SIDEBAR_MODAL_CONFIG } from "./modals/impactProcessSidebar/ImpactProcessSidebarModal_config";
import { IMPACT_STAKEHOLDERS_SIDEBAR_MODAL_CONFIG } from "./modals/impactStakeholderSidebar/ImpactStakeholdersSidebarModal_config";
import { IMPACT_TAGS_SIDEBAR_MODAL_CONFIG } from "./modals/impactTagsSidebar/ImpactTagsSidebarModal_config";
import { IMPACT_TYPES_SIDEBAR_MODAL_CONFIG } from "./modals/impactTypesSidebar/ImpactTypesSidebarModal_config";
import { IMPACT_NOTES_SIDEBAR_MODAL_CONFIG } from "./modals/noteCountSidebar/ImpactNotesSidebarModal_config";

export interface ImpactsGridColumnBuilderProps {
  canEdit: boolean;
  organisationId: number;
  projectId: number;
  userCanViewImpacts: boolean;
  columns: FP.Entities.IColumnDef[];
  projectTeamMembers: any[];
}

export class ImpactsGridColumnBuilder extends BaseGridColumnBuilder {
  gridColumns: Dictionary<ColDef>;
  gridToastService = GridToastService;
  httpProgress = ProgressIndicatorModel;
  gridProps: ImpactsGridColumnBuilderProps;
  impactsProvider: IImpactsApi = ImpactsApi;
  columnDefs: Dictionary<() => ColDef>;
  organisationId: number;
  projectId: number;
  projectTeamMembers: any[];

  constructor(gridProps: ImpactsGridColumnBuilderProps) {
    super(ImpactsApi.updateField, gridProps.organisationId, gridProps.projectId, gridProps.canEdit);
    this.gridProps = gridProps;
    this.organisationId = gridProps.organisationId;
    this.projectId = gridProps.projectId;
    this.projectTeamMembers = gridProps.projectTeamMembers;

    this.init();
  }

  private init = () => {
    this.columnDefs = {
      [CommonColDefFieldNamesEnum.Selected]: () =>
        new SelectionColumnBuilder().makeSelectable().generateColumnOptions(),
      [CommonColDefFieldNamesEnum.RefNumber]: () => this.buildRefNumberColumn(),
      [CommonColDefFieldNamesEnum.Name]: () => this.buildNameColumn(),
      [CommonColDefFieldNamesEnum.Description]: () => this.buildDescriptionColumn(),
      [CommonColDefFieldNamesEnum.ProgressStatus]: () => this.buildProgressStatusColumn(),
      [CommonColDefFieldNamesEnum.StartDate]: () => this.buildStartDateColumn(),
      [CommonColDefFieldNamesEnum.EndDate]: () => this.buildEndDateColumn(),
      [CommonColDefFieldNamesEnum.Owner]: () => this.buildOwnerColumn(),
      [ImpactColDefFieldNamesEnum.ImpactTypes]: () =>
        this.buildPillsColumn(
          ImpactColDefFieldNamesEnum.ImpactTypes,
          I18n.t("grids.impactTypes"),
          IMPACT_TYPES_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions(),
      [ImpactColDefFieldNamesEnum.ImpactLevel]: () => this.buildImpactLevelColumn(),
      [ImpactColDefFieldNamesEnum.TotalPeopleImpacted]: () => this.buildTotalPeopleImpactedColumn(),
      [ImpactColDefFieldNamesEnum.ImpactGroups]: () =>
        this.buildPillsColumn(
          ImpactColDefFieldNamesEnum.ImpactGroups,
          I18n.t("grids.impactGroups"),
          IMPACT_IMPACT_GROUPS_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions(),
      [ImpactColDefFieldNamesEnum.BusinessAreas]: () =>
        this.buildPillsColumn(
          ImpactColDefFieldNamesEnum.BusinessAreas,
          I18n.t("grids.businessAreas"),
          IMPACT_BUSINESS_AREAS_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions(),
      [ImpactColDefFieldNamesEnum.Locations]: () =>
        this.buildPillsColumn(
          ImpactColDefFieldNamesEnum.Locations,
          I18n.t("grids.locations"),
          IMPACT_LOCATIONS_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions(),
      [ImpactColDefFieldNamesEnum.Actions]: () =>
        this.buildPillsColumn(
          ImpactColDefFieldNamesEnum.Actions,
          I18n.t("grids.actions"),
          IMPACT_ACTIONS_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions(),
      [CommonColDefFieldNamesEnum.Processes]: () =>
        this.buildPillsColumn(
          CommonColDefFieldNamesEnum.Processes,
          I18n.t("grids.processes"),
          IMPACT_PROCESS_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions(),
      [ImpactColDefFieldNamesEnum.Milestones]: () =>
        this.buildPillsColumn(
          ImpactColDefFieldNamesEnum.Milestones,
          I18n.t("grids.milestones"),
          IMPACT_MILESTONES_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions(),
      [CommonColDefFieldNamesEnum.NoteCount]: () => this.buildNoteCountColumn(),
      [CommonColDefFieldNamesEnum.Tags]: () =>
        this.buildPillsColumn(
          CommonColDefFieldNamesEnum.Tags,
          I18n.t("grids.tags"),
          IMPACT_TAGS_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions(),
      [ImpactColDefFieldNamesEnum.ProjectStakeholders]: () =>
        this.buildPillsColumn(
          ImpactColDefFieldNamesEnum.ProjectStakeholders,
          I18n.t("grids.stakeholders"),
          IMPACT_STAKEHOLDERS_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions(),
      [ImpactColDefFieldNamesEnum.Audiences]: () =>
        this.buildJSONPillsColumn(
          ImpactColDefFieldNamesEnum.Audiences,
          I18n.t("grids.audiences"),
          IMPACT_AUDIENCES_SIDEBAR_MODAL_CONFIG
        ).generateColumnOptions(),
      [CommonColDefFieldNamesEnum.ReviewedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.ReviewedBy,
          headerName: I18n.t("grids.reviewedBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.ReviewedOn]: () =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.ReviewedOn,
          headerName: I18n.t("grids.lastReviewedOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.ReviewedOn, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.ReviewedOn)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.NextReviewDue]: () =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.NextReviewDue,
          headerName: I18n.t("grids.nextReviewDue")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.NextReviewDue, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.NextReviewDue)
          .withEndDateCellRenderer()
          .withCellClass(["ag-cell--not-editable"])
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.CreatedBy,
          headerName: I18n.t("grids.createdBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.CreatedAt]: () =>
        new DateColumnBuilder({ field: CommonColDefFieldNamesEnum.CreatedAt, headerName: I18n.t("grids.createdOn") })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.CreatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.CreatedAt)
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.ModifiedBy]: () =>
        new NameColumnBuilder({
          field: CommonColDefFieldNamesEnum.ModifiedBy,
          headerName: I18n.t("grids.lastModifiedBy"),
          pinned: false
        })
          .makeEditable(false)
          .makeReadOnly()
          .generateColumnOptions(),
      [CommonColDefFieldNamesEnum.UpdatedAt]: () =>
        new DateColumnBuilder({
          field: CommonColDefFieldNamesEnum.UpdatedAt,
          headerName: I18n.t("grids.lastModifiedOn")
        })
          .makeEditable(false)
          .makeReadOnly()
          .withCellEditor(CommonColDefFieldNamesEnum.UpdatedAt, "")
          .withComparator()
          .setValueFormatter(CommonColDefFieldNamesEnum.UpdatedAt)
          .generateColumnOptions()
    };
  };

  generateColumnDefs = (): ColDef[] => {
    let res: ColDef[] = [];

    if (this.gridProps.columns.length === 0) {
      return _.map(this.columnDefs, e => {
        return e();
      });
    }
    res.push(this.columnDefs[CommonColDefFieldNamesEnum.Selected]());
    this.gridProps.columns.forEach(e => {
      if (e.isCore) {
        let col = this.columnDefs[e.colName];
        if (typeof col === "function") {
          res.push(col());
        }
      } else {
        let col = new DynamicColumnBuilder(
          DYNAMIC_COLUMN_CONFIG({
            field: e.colName,
            headerName: e.header
          })
        )
          .makeSelectable(this.gridProps.canEdit)
          .makeEditable(false)
          .makeReadOnly(!this.gridProps.canEdit);

        if (this.gridProps.canEdit) {
          col.setEditableOnDoubleClick(this.updateDynamicFieldValue(e.customFieldId), e.colName, e.header);
        }
        res.push(col.generateColumnOptions());
      }
    });

    return res;
  };

  buildRefNumberColumn = () => {
    let model = new RefNumberColumnBuilder()
      .setColumnOptions(REF_NUMBER_COLUMN_CONFIG({ headerName: I18n.t("grids.refNo") }))
      .withLinkCell(
        impactId => Pages.projects.impacts.extendedView.generateLink(this.organisationId, this.projectId, impactId),
        CommonColDefFieldNamesEnum.RefNumber
      )
      .setFilterOptions(REF_NUMBER_FILTER_CONFIG);

    return model.generateColumnOptions();
  };
  buildNameColumn = () => {
    let model = new NameColumnBuilder()
      .setColumnOptions(NAME_COLUMN_CONFIG({ headerName: "Name" }))
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(NAME_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      // make cell editable
      model.createValueSetter(this.updateName);
    }

    return model.generateColumnOptions();
  };

  buildDescriptionColumn = () => {
    let model = new DescriptionColumnBuilder()
      .setColumnOptions(DESCRIPTION_COLUMN_CONFIG())
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(false)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(DESCRIPTION_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      model.setEditableOnDoubleClick(this.updateDescription);
    }

    return model.generateColumnOptions();
  };

  buildProgressStatusColumn = () => {
    const progressStatuses = mapProgressStatuses();
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions(AUTOCOMPLETE_COLUMN_CONFIG({ field: "progressStatus", headerName: I18n.t("grids.status") }))
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG,
        ...PROGRESS_STATUS_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellEditor()
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.progressStatus = +params.newValue.key;
        this.updateProgressStatus(params.data.id, params.newValue.key);

        return true;
      })
      .withCellRenderer(params => {
        if (!params.data.progressStatus || !progressStatuses) return null;
        const progress = progressStatuses.find(e => e.key === params.data.progressStatus + "");

        if (!progress) return "";
        return <>{progress.label}</>;
      })
      .setColumnOptions(PROGRESS_STATUS_COLUMN_CONFIG(progressStatuses));

    return model.generateColumnOptions();
  };

  buildStartDateColumn = () => {
    let model = new DateColumnBuilder()
      .setColumnOptions(
        DATE_COLUMN_CONFIG({
          field: CommonColDefFieldNamesEnum.StartDate,
          headerName: I18n.t("grids.startDate")
        })
      )
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withComparator()
      .withCellEditor(CommonColDefFieldNamesEnum.StartDate, "")
      .setValueFormatter(CommonColDefFieldNamesEnum.StartDate)
      .setValueGetterByFieldFn(CommonColDefFieldNamesEnum.StartDate)
      .setFilterOptions(DATE_FILTER_CONFIG)
      .setValueSetter(params => {
        return true;
      })
      .setColumnOptions({
        cellEditorParams: {
          field: CommonColDefFieldNamesEnum.StartDate,
          defaultDate: moment(),
          onDatepickerClick: (params: ICellEditorParams, e) => {
            if (!e) {
              params.data[CommonColDefFieldNamesEnum.StartDate] = defaultDateTimeString;
              return params.data[CommonColDefFieldNamesEnum.StartDate];
            }

            let startDate = moment(e);
            let endDate =
              params.data[CommonColDefFieldNamesEnum.EndDate] &&
              params.data[CommonColDefFieldNamesEnum.EndDate] !== defaultDateTimeString &&
              moment(params.data[CommonColDefFieldNamesEnum.EndDate]);

            if (!(startDate === endDate) && (!endDate || endDate > startDate)) {
              this.updateStartDate(params.data.id, startDate.toDate());
              return e;
            }
            return params.data[CommonColDefFieldNamesEnum.StartDate];
          }
        }
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildEndDateColumn = () => {
    let model = new DateColumnBuilder()
      .setColumnOptions(
        DATE_COLUMN_CONFIG({ field: CommonColDefFieldNamesEnum.EndDate, headerName: I18n.t("grids.endDate") })
      )
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withComparator()
      .withCellEditor(CommonColDefFieldNamesEnum.EndDate, "")
      .setValueFormatter(CommonColDefFieldNamesEnum.EndDate)
      .setValueGetterByFieldFn(CommonColDefFieldNamesEnum.EndDate)
      .setFilterOptions(DATE_FILTER_CONFIG)
      .setValueSetter(params => {
        return true;
      })
      .setColumnOptions({
        cellEditorParams: {
          field: CommonColDefFieldNamesEnum.EndDate,
          defaultDate: moment(),
          onDatepickerClick: (params: ICellEditorParams, e) => {
            if (!e) {
              params.data[CommonColDefFieldNamesEnum.EndDate] = defaultDateTimeString;
              return params.data[CommonColDefFieldNamesEnum.EndDate];
            }

            let endDate = moment(e);
            let startDate =
              params.data[CommonColDefFieldNamesEnum.StartDate] &&
              params.data[CommonColDefFieldNamesEnum.StartDate] !== defaultDateTimeString &&
              moment(params.data[CommonColDefFieldNamesEnum.StartDate]);

            if (
              !(endDate === startDate) &&
              (!startDate || laterDateComparator(endDate.toDate(), startDate.toDate()) === true)
            ) {
              this.updateEndDate(params.data.id, endDate.toDate());
              return e;
            }
            return params.data[CommonColDefFieldNamesEnum.EndDate];
          }
        }
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildOwnerColumn = () => {
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions({
        ...AUTOCOMPLETE_COLUMN_CONFIG({ field: CommonColDefFieldNamesEnum.Owner, headerName: I18n.t("grids.owner") }),
        ...OWNER_NAME_COLUMN_CONFIG(this.projectTeamMembers)
      })
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG,
        ...OWNER_NAME_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellEditor()
      .setValueGetter(e => {
        if (!e.data.ownerName) return;
        return `${e.data.ownerName ?? ""}`;
      })
      .setValueSetter(params => {
        if (!params.newValue) return false;

        params.data.ownerId = params.newValue.id;
        params.data.ownerName = `${params.newValue.firstName} ${params.newValue.lastName}`;

        this.updateOwnerName(params.data.id, params.newValue.id);
        return true;
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildImpactLevelColumn = () => {
    const impactLevels = mapImpactLevels();
    let model = new AutocompleteColumnBuilder()
      .setColumnOptions({
        ...IMPACT_LEVEL_COLUMN_CONFIG(impactLevels)
      })
      .setFilterOptions({
        ...AUTOCOMPLETE_FILTER_CONFIG,
        ...IMPACT_LEVEL_FILTER_CONFIG
      })
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .withCellEditor()
      .setValueSetter(params => {
        if (!params.newValue) return false;
        let newVal = Enums.Translator.ImpactLevelShortToLong(+params.newValue.key);
        this.updateImpactLevel(params.data.id, newVal);
        return true;
      })
      .withCellRenderer(params => {
        const impact: FP.Entities.IImpactSummary = params.data;
        return impact.impactLevel > 0 ? Enums.Translator.ImpactLevel(impact.impactLevel) : null;
      });

    if (this.gridProps.canEdit) {
      model.makeDeletable();
    }

    return model.generateColumnOptions();
  };

  buildNoteCountColumn = () => {
    let model = new NoteCountColumnBuilder()
      .setColumnOptions(NOTE_COUNT_COLUMN_CONFIG())
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit);

    if (this.gridProps.canEdit) {
      const impactNotesModal = (impact: FP.Entities.IImpact) =>
        this.gridModalBuilder
          .constructSideModal()
          .setModalOptions(IMPACT_NOTES_SIDEBAR_MODAL_CONFIG(this.organisationId, this.projectId, impact))
          .generateModal();

      model.setEditableOnDoubleClick(impactNotesModal);
    }
    return model.generateColumnOptions();
  };

  buildTotalPeopleImpactedColumn = () => {
    let model = new TotalPeopleImpactedColumnBuilder()
      .setColumnOptions(TOTAL_PEOPLE_IMPACTED_COLUMN_CONFIG({ headerName: I18n.t("grids.numberImpacted") }))
      .makeSelectable(this.gridProps.canEdit)
      .makeEditable(this.gridProps.canEdit)
      .makeReadOnly(!this.gridProps.canEdit)
      .setFilterOptions(TOTAL_PEOPLE_IMPACTED_FILTER_CONFIG);

    if (this.gridProps.canEdit) {
      // make cell editable
      model.createValueSetter(this.updateTotalPeopleImpacted);
    }

    return model.generateColumnOptions();
  };

  //#region Update Field Methods

  updateDynamicFieldValue = customFieldId => async (entityId: number, text: string) => {
    const customFieldProvider = CustomFieldsApi;
    await customFieldProvider.updateImpactCustomFieldValue(
      this.organisationId,
      this.projectId,
      customFieldId,
      entityId,
      text
    );
  };

  updateDescription = async (entityId: number, text: string) => {
    await this.updateTextField(ImpactField.DESCRIPTION, entityId, text);
  };
  updateName = async (entityId: number, text: string) => {
    await this.updateTextField(ImpactField.NAME, entityId, text);
  };
  updateStartDate = async (entityId: number, date: Date) => {
    await this.updateDateField(ImpactField.START_DATE, entityId, date);
  };
  updateEndDate = async (entityId: number, date: Date) => {
    await this.updateDateField(ImpactField.END_DATE, entityId, date);
  };
  updateProgressStatus = async (entityId: number, data: any) => {
    await this.updateIdField(ImpactField.PROGRESS_STATUS, entityId, data);
  };
  updateOwnerName = async (entityId: number, text: any) => {
    await this.updateIdField(ImpactField.OWNER, entityId, text);
  };
  updateImpactType = async (entityId: number, numeric: number) => {
    await this.updateNumericField(ImpactField.IMPACT_TYPE, entityId, numeric);
  };
  updateImpactLevel = async (entityId: number, numeric: number) => {
    await this.updateNumericField(ImpactField.IMPACT_LEVEL, entityId, numeric);
  };
  updateTotalPeopleImpacted = async (entityId: number, numeric: number) => {
    await this.updateNumericField(ImpactField.NUMBER_IMPACTED, entityId, numeric);
  };
  //#endregion
}
